import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import QuemSomos from './components/QuemSomos'
import Projetos from './components/Projetos'
import {Atuacao} from './components/Atuacao'
import Equipe from './components/Equipe'


function App() {
  return (
    <React.Fragment>
      <Router>
        <Header />
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/quem-somos" component={QuemSomos} />
          <Route path="/portfolio" component={Projetos} />
          <Route path="/atuacao" component={Atuacao} />
          <Route path="/equipe" component={Equipe} />
        </div>
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
