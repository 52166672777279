import React from 'react';
import './Atuacao.css';

function Atuacao_Head() {
    return(
        <div class="atuacao-banner">
           <h1>ÁREAS DE ATUAÇÃO</h1>
        </div>
    )
}

function Atuacao_Content() {
    return (
        <div>

            <div class="atuacao-container">
                <div class="atuacao">
                    <div class="atuacao-title">
                        <h1 class="atuacao-title-word">Consultoria Técnica</h1> 
                    </div>
                    <div class="atuacao-conteudo">
                        <p>Elaboração de Editais de Licitação; Acompanhamento de Licitações; 
                            Pareceres e Laudos Técnicos; Avaliações de Imóveis; Estudos e Projetos de 
                            Viabilidades Técnicas para captação de recursos em Órgãos Financiadores 
                            (Ministérios, Estado, Bancos etc.).</p>
                    </div>
                </div>

                <div class="atuacao atuacao2">
                    <div class="atuacao-title">
                        <h1 class="atuacao-title-word">Projetos</h1> 
                    </div>
                    <div class="atuacao-conteudo">
                        <p>Coordenação e Elaboração de Projetos de Edifícios Residenciais, Comerciais, 
                            Industriais, Serviços e Institucionais (escolas, centros culturais, 
                            estabelecimentos de saúde, espaços esportivos, praças e parques); Revitalização 
                            de Edifícios; Infraestrutura (sistema viário, água, esgoto, energia elétrica, 
                            comunicações e dados, energia fotovoltaica etc); Acessibilidade; Mobilidade; 
                            Sinalização Urbana.</p>
                    </div>
                </div>

                <div class="atuacao atuacao3">
                    <div class="atuacao-title">
                        <h1 class="atuacao-title-word">Planejamento Urbano</h1> 
                    </div>
                    <div class="atuacao-conteudo">
                        <p>Elaboração de Plano Diretor, Zoneamento, Código de Edificações; Plano de Desenvolvimento 
                            Urbano; Requalificação Urbana; Paisagismo; Áreas Recreativas e Lazer; Segurança; Licenciamento 
                            Ambiental; Impacto de Trânsito; Saúde/Melhorias sanitárias.</p>
                    </div>
                </div>

                <div class="atuacao">
                    <div class="atuacao-title">
                        <h1 class="atuacao-title-word">Orçamento de Obras</h1> 
                    </div>
                    <div class="atuacao-conteudo">
                        <p>Elaboração de orçamentos sintéticos e analíticos; Especificações Técnicas; Cronogramas 
                            Físico-Financeiros.</p>
                    </div>
                </div>

                <div class="atuacao atuacao2">
                    <div class="atuacao-title">
                        <h1 class="atuacao-title-word">Fiscalização de Obras</h1> 
                    </div>
                    <div class="atuacao-conteudo">
                        <p>Acompanhamento diário de obras; Relatórios de Medição; Análise do andamento da obra.</p>
                    </div>
                </div>

                <div class="atuacao atuacao3">
                    <div class="atuacao-title">
                        <h1 class="atuacao-title-word">Execução de Obras</h1> 
                    </div>
                    <div class="atuacao-conteudo">
                        <p>Construção; Reformas; Manutenção de Edificações.</p>
                    </div>
                </div>

                <div class="atuacao">
                    <div class="atuacao-title">
                        <h1 class="atuacao-title-word">Organização de Infraestrutura</h1> 
                    </div>
                    <div class="atuacao-conteudo">
                        <p>Estruturação e implementação de setores de Projetos, Obras e Manutenção de 
                            Edifícios e Áreas Urbanas de Empresas, Órgãos Públicos e Prefeituras. </p>
                    </div>
                </div>

                <div class="atuacao atuacao2">
                    <div class="atuacao-title">
                        <h1 class="atuacao-title-word">Capacitação</h1> 
                    </div>
                    <div class="atuacao-conteudo">
                        <p>Cursos e Treinamentos nas áreas de Infraestrutura, Projetos, Fiscalização 
                            de Obras, Manutenção de Edificações e Áreas Públicas.</p>
                    </div>
                </div>

            </div>

        </div>
    )
}

function Atuacao() {
    return (
        <div>
            <Atuacao_Head />
            <Atuacao_Content />
        </div>
    )
}

export {Atuacao, Atuacao_Content};