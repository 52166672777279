export const construcoes = [
    {
        obra: "Condomínio Residencial Albatroz",
        proprietario: "Edison Vivas de Resende e outros",
        endereco: "Rua T-38, Setor Bueno",
        periodo: "1986 a 1989",
        contato: "",
    },
    {
        obra: "Condomínio Residencial Lago Rico",
        proprietario: "Abrão Afiune Neto e outros",
        endereco: "Rua T-38 com T-62, Setor Bueno",
        periodo: "1989 a 1992",
        contato: "",
    },
    {
        obra: "Clínica Odontológica Odontoesp",
        proprietario: "Antônio Carlos e outros",
        endereco: "Rua 89, Setor Sul",
        periodo: "1989 a 1992",
        contato: "Antônio Carlos - 9637 1138",
    },
    {
        obra: "Escola da Natação Mergulho",
        proprietario: "Cláudio Almeida Borges",
        endereco: "Rua T-29, 1225, St. Bueno",
        periodo: "ago/95 a jul/96",
        contato: "",
    },
    {
        obra: "Ampliação do Hospital da Criança",
        proprietario: "José Eduardo de Souza e outros",
        endereco: "Rua 86 Q-32 L-2/4/14/16/38 S. SUL",
        periodo: "abr/96 a mar/98",
        contato: "",
    },
    {
        obra: "Residência Cid",
        proprietario: "Cid Barbosa Lima",
        endereco: "R.Tamboril Q-133 L 36A Santa Genoveva",
        periodo: "mai/96 a mai/97",
        contato: "",
    },
    {
        obra: "Residência Nazira",
        proprietario: "Nazira Fátima Elias",
        endereco: "R. Palmital QD-1 Jd. Himalaia - Aparecida de Goiânia",
        periodo: "jul/96 a abr/97",
        contato: "",
    },
    {
        obra: "Comercial Sindicato Odontologistas",
        proprietario: "Sindicato dos Odontologistas de Goiás",
        endereco: "R. T-27 Q-66 L-06A ST. BUENO",
        periodo: "ago/96 a ago/97",
        contato: "Meire - 3285-4824",
    },
    {
        obra: "Residência Rogério",
        proprietario: "Rogério Tércio Ranulfo",
        endereco: "Rua das Mandioqueiras, Aldeia do Vale",
        periodo: "nov/98 a nov/99",
        contato: "3241 1599",
    },
    {
        obra: "Residência Ilza",
        proprietario: "Ilza Vitório Rocha",
        endereco: "Rua das Quaresmeira, Aldeia do Vale",
        periodo: "jan/98 a ago/98",
        contato: "",
    },
    {
        obra: "Residência Paulo Luiz",
        proprietario: "Paulo Luiz Carvalho Francescantonio",
        endereco: "Av. Floresta, Aldeia do Vale",
        periodo: "ago/97 a dez/03",
        contato: "3567 3063",
    },
    {
        obra: "Residência Henrique",
        proprietario: "Henrique André Rodovalho",
        endereco: "Rua das Gameleiras, Aldeia do Vale",
        periodo: "2003",
        contato: "3225 2263",
    },
    {
        obra: "Edifício Comercial Metrópolis - Área 233,88",
        proprietario: "Metropolis Consultoria e Planejamento Ltda",
        endereco: "Av. José Rodrigues de Morais Neto, 2395 Parque Amazonia - Goiânia",
        periodo: "2002/2003",
        contato: "(62) 3548-6745",
    },
    {
        obra: "Residência Guilherme- Área 390,91m²",
        proprietario: "Guilherme Aragão de Menezes",
        endereco: "Rua B-17 Qd 10 Lt 17 Res. Jardins Paris - Goiânia",
        periodo: "2004/2005",
        contato: "(62) 9972-2172",
    },
    {
        obra: "Residência Paulo Ronaldo - Área 431,80m²",
        proprietario: "Paulo Ronaldo Jubé Ribeiro",
        endereco: "Rua B-17 Qd 10B Lt 18 Res. Jardins Paris - Goiânia",
        periodo: "2004/2005",
        contato: "(62) 9975-7050",
    },
    {
        obra: "Residência Maria Amélia - Área 364,14m²",
        proprietario: "Maria Amélia Gonçalves de Ávila",
        endereco: "Rua B-30 Qd 24-B Lt 8 - Jardins Paris",
        periodo: "2006/2007",
        contato: "9294 2007",
    },
    {
        obra: "Galpão Cooperativa Morrinhos - Área 1224m²",
        proprietario: "Cooperativa dos Produtores de Leite de Morrinhos",
        endereco: "DAIMO, Qd 1 Lt 7 Distrito Agroindustrial de Morrinhos",
        periodo: "2009/2010",
        contato: "(64) 3417-1216",
    },
    {
        obra: "Edifício Comercial Nefroclínica - Área 1.632,98m²",
        proprietario: "Nefroclínica - Clinica de Doenças Renais",
        endereco: "Rua C-149 Qd 323 Lts 6/7 Jardim América - Goiânia - Go",
        periodo: "2009/2010",
        contato: "(62) 3251-1911",
    },
    {
        obra: "Residência Eduardo 1 - Área 241,77m²",
        proprietario: "Eduardo Vilela",
        endereco: "Rua Lago 13 Qd 11 Lt 21 Condomínio do Lago - Goiânia",
        periodo: "2010 - 2012",
        contato: "(62) 98117-2103",
    },
    {
        obra: "Residência Eduardo 2 - Área 237m²",
        proprietario: "Eduardo Vilela",
        endereco: "Rua Lago 4 Qd 19 Lt 08 Condomínio do Lago 2- Goiânia",
        periodo: "abril/2016 - abril 2017",
        contato: "(62) 98117-2103",
    }
];

export const reformas = [
    {
        obra: "Edfício Residencial Cyntia",
        proprietario: "Antônio Celso e outros",
        endereco: "AV. D Nº 345 ST. MARISTA",
        periodo: "ago/95 a mar/96",
        contato: "241 7023"
    },
    {
        obra: "Centro de Saude Conjunto Aruanã",
        proprietario: "Sec. Municipal de Saúde",
        endereco: "R. Urucara, Q-3 Lt 11 Cj. Aruanã",
        periodo: "jun/96 a set/96",
        contato: ""
    },
    {
        obra: "Edfício Residencial Lorena",
        proprietario: "Ana Domitila e outros",
        endereco: "Rua 5, Setor Oeste",
        periodo: "out/95 a abr/96",
        contato: "229 3708"
    },
    {
        obra: "Reforma e Ampliação do Hospital da Criança",
        proprietario: "José Eduardo de Souza e outros",
        endereco: "Rua 86 Q-32 L-2/4/14/16/38 S. SUL",
        periodo: "abr/96 a mar/98",
        contato: "239 1000"
    },
    {
        obra: "Residência",
        proprietario: "Hélio Stefani",
        endereco: "Rua J-32, com Al. Pamplona, Setor Jaó",
        periodo: "mar/98 a dez/98",
        contato: "206 1313"
    },
    {
        obra: "Edifício Comercial",
        proprietario: "Ipanema Segurança",
        endereco: "Av. B. Sayão Q66A L24 V. Brasília - Aparecida de Goiânia",
        periodo: "nov/99 a dez/99",
        contato: ""
    },
    {
        obra: "Edifício Residencial Tainá",
        proprietario: "Milton Cury e outros",
        endereco: "Rua 6 Nº 480 St.Oeste",
        periodo: "nov/01 a mai/02",
        contato: "215 7621"
    },
    {
        obra: "Residência Helton",
        proprietario: "Helton de Oliveira Aguiar",
        endereco: "R. 131 Q44 L13 St Sul",
        periodo: "out/00 a abr/02",
        contato: "Dalva - 241 2557"
    },
    {
        obra: "Residência Gladis",
        proprietario: "Gladis de Simas Aragão",
        endereco: "R. C-180 Q 609 L 11 Nova Suiça",
        periodo: "out/01 a abr/02",
        contato: "259 55 12"
    },
    {
        obra: "Residência Darwin - área 175m²",
        proprietario: "Darwin Raphael Antonio Montoro",
        endereco: "Rua 224 Esq. c/ 201 Setor Leste Vila Nova - Goiânia",
        periodo: "2002",
        contato: "3291-2771"
    },
    {
        obra: "Residência Eleuse",
        proprietario: "Eleuse Machado de Brito",
        endereco: "R. T-62 Q128 L-10 St. Bueno",
        periodo: "jan/03 a mar/04",
        contato: "241 0280"
    },
    {
        obra: "Edifício Office Flamboyant, Jd Goiás - reforma",
        proprietario: "Abrão Helou e Braga Nascimento Advogados Associados",
        endereco: "Ed. Office Flamboyant, Jd Goiás",
        periodo: "mai/03 a ago/03",
        contato: "Vandaira - 515 15 05"
    },
    {
        obra: "Edifício Residencial Cardeal",
        proprietario: "Márcia Reis e outros",
        endereco: "Rua T-38, Setor Bueno",
        periodo: "2003",
        contato: "255 24 77"
    },
    {
        obra: "Edifício Residencial Bela Cardoso - área 750,00m²",
        proprietario: "Condomínio do Edifício Bela Cardoso",
        endereco: "Rua 1126 nº 57 Qd 232 Lt 01 Setor Marista - Goiânia",
        periodo: "2004",
        contato: ""
    },
    {
        obra: "Edifício Residencial Vierzon",
        proprietario: "Condomínio do Edifício Vierzon",
        endereco: "Av. T-5 Qd 143 Lts 2/3 Setor Bueno",
        periodo: "2010",
        contato: "3095-3768"
    }
];

export const consultoria = [
    {
        obra: "Serviços	Prestação de serviços técnicos de engenharia e arquitetura para elaboração de análise, assessoria, coordenação, especificações, estudo de viabilidade técnica, elaboração de orçamentos, fiscalização de obras e serviços, laudo, levantamento , Projetos, parecer e vistoria – EN Goiânia – Regional de Goiás – área total de 62.330,73 m2. ",
        proprietario: "Caixa Econômica Federal - Escritório de Negócios de Goiânia",
        endereco: "Av. Anhanguera, com Rua 11, 8o. Andar - GIINF",
        periodo: "Contrato Contrato de 30/04/1998 a 30/10/2002 1997",
        contato: "(GIMAT) - 3612-1900"
    },
    {
        obra: "Prestação de serviços técnicos de engenharia e arquitetura para elaboração de análise, assessoria, coordenação, especificações, estudo de viabilidade técnica, elaboração de orçamentos, fiscalização de obras e serviços, laudo, levantamento , Projetos, parecer e vistoria – EN Goiânia – Regional de Goiás – área total de 53.827,03 m2. – Contrato 1686/2002",
        proprietario: "Caixa Econômica Federal - Escritório de Negócios de Goiânia",
        endereco: "Av. Anhanguera, com Rua 11, 8o. Andar - GIINF",
        periodo: "Contrato de 01/11/2002 -26/01/2006",
        contato: "(GIMAT) - 3612-1900"
    },
    {
        obra: "Prestação de serviços técnicos de engenharia e arquitetura para elaboração de análise, assessoria, coordenação, especificações, estudo de viabilidade técnica, elaboração de orçamentos, fiscalização de obras e serviços, laudo, levantamento , Projetos, parecer e vistoria – EN Goiânia – Regional de Goiás – área total de 51.551,01 m2. - – Contrato 2878/2005",
        proprietario: "Caixa Econômica Federal - Escritório de Negócios de Goiânia",
        endereco: "Av. Anhanguera n. 5829 Centro - Goiânia",
        periodo: "27/01/2006 – 26/09/2008",
        contato: "GILIC - 62 3216-1973 "
    },
    {
        obra: "Prestação de serviços técnicos de engenharia e arquitetura para elaboração de análise, assessoria, coordenação, especificações, estudo de viabilidade técnica, elaboração de orçamentos, fiscalização de obras e serviços, laudo, levantamento , Projetos, parecer e vistoria – Regionais Goiás e Mato Grosso do Sul – área total de 91.025,17 m2. – Contrato 4325/2010",
        proprietario: "Caixa Econômica Federal - GILOG-GO",
        endereco: "Av. Anhanguera n. 5829 Centro - Goiânia",
        periodo: "Contrato de 08/12/2010 – 02/07/2016",
        contato: "(GILOG) 62 3216-1973"
    },
    {
        obra: "Prestação de serviços técnicos de engenharia e arquitetura para elaboração de análise, assessoria, coordenação, especificações, estudo de viabilidade técnica, elaboração de orçamentos, fiscalização de obras e serviços, laudo, levantamento , Projetos, parecer e vistoria – Regionais de Mato Grosso e Mato Grosso do Sul – área total de 30.000,01 m2. – Contrato 2226/2017",
        proprietario: "Caixa Econômica Federal - GILOG-GO",
        endereco: "Av. Anhanguera n. 5829 Centro - Goiânia",
        periodo: "Contrato de 10/04/2017- 30/12/2018",
        contato: "(GILOG) 62 3216-1973"
    },
    {
        obra: "Projeto de Arquitetura - Área 3.481,07m²",
        proprietario: "Drift Participações e Empreendimentos S/A",
        endereco: "Av. Anhanguera Qd 61 Lt 83, 85, 136 Centro - Goiânia",
        periodo: "2007",
        contato: "(62) 9284-0627"
    },
    {
        obra: "Projeto de Arquitetura - área 1.164,90m²",
        proprietario: "Erineu Taveira e Souza",
        endereco: "Av. Marechal Rondon, Qd 19 Lt 1-C Centro - Ji-Paraná - RO",
        periodo: "2008",
        contato: "(69) 3421-0966"
    },
    {
        obra: "Projeto de Acessibilidade, Arquitetura de Interiores, Orçamento - 1.571,42m²",
        proprietario: "Drift Participações e Empreendimentos S/A",
        endereco: "Av. Anhanguera Qd 61 Lt 83, 85, 136 Centro - Goiânia",
        periodo: "2008",
        contato: "(62) 9284-0627"
    },
    {
        obra: "Prestação de serviços técnicos de arquitetura e engenharia, Projetos, Especificações, Orçamentos, Supervisão de execução  de Obra Região Norte e Vale do Meia Ponte – área total: 6491,00 m2 – Contrato 053/2002",
        proprietario: "Secretaria Municipal de Obras de Goiânia, Próprios Públicos – Reg. Sul",
        endereco: "COMOB - Av. Atílio Correia Lima, Vila Aurora",
        periodo: "Contrato de 2002 - 2004",
        contato: "Josias - 3524 2150"
    },
    {
        obra: "Prestação de serviços técnicos de arquitetura e engenharia, Projetos, Especificações, Orçamentos, Supervisão de execução  de Obra Regiões Centro e Sul– área total: 6491,00 m2 – Contrato 056/2002",
        proprietario: "Secretaria Municipal de Educação de Goiânia, Região Noroeste",
        endereco: "COMOB - Av. Atílio Correia Lima, Vila Aurora",
        periodo: "Contrato de 2002 - 2004",
        contato: "Josias - 3524 2150"
    },
    {
        obra: "Projeto de Pavimentação asfaltica - área 13.011,27m²",
        proprietario: "Cooperativa dos Produtores de Leite de Morrinhos",
        endereco: "DAIMO, Qd 1 Lt 7 Distrito Agroindustrial de Morrinhos",
        periodo: "2009",
        contato: "(64) 3417-1216"
    },
    {
        obra: "Projetos de fundação, estrutura, hidráulico, elétrico, climat.",
        proprietario: "Procuradoria Geral de Justiça do Estado do Tocantins",
        endereco: "QD 202 NORTE CONJ. 01 LT 5 E 6 AV LO 04 - Palmas - To",
        periodo: "05/11/13 a 28/02/2014",
        contato: "(63) 3216-7619"
    },
    {
        obra: "Elaboração de orçamento, Assessoramento para contratação de empresa e fiscalização da obra Sede Administrativa UNIMED Cooperativa Médica",
        proprietario: "UNIMED Goiânia",
        endereco: "Av. T-7 N. 650 Setor Bueno",
        periodo: "19/03/2012 – 13/03/2019",
        contato: "62 9639-6083 - Kleber"
    },
    {
        obra: "Projeto de Arquitetura p/ ampl. de galpão - área 1.277,24m²",
        proprietario: "Cooperativa dos Produtores de Leite de Morrinhos",
        endereco: "DAIMO, Qd 1 Lt 7 Distrito Agroindustrial de Morrinhos",
        periodo: "2009/2010",
        contato: "(64) 3417-1216"
    },
    {
        obra: "Prestação de serviços de elaboração de projeto executivo de reforma do PAR - PMCMV– Residencial Portal da Serrra/TO,– área total de 6.305,60 m2. - – Contrato 5256/2014",
        proprietario: "Caixa Econômica Federal - GILOG-GO",
        endereco: "Av. Anhanguera n. 5829 Centro - Goiânia",
        periodo: "2014",
        contato: "(GILOG) 62 3216-1973"
    },
    {
        obra: "Prestação de serviços de elaboração de projeto executivo de reforma do PAR - PMCMV– Residencial Portal da Serrra/TO,– área total de 9.458,40 m2. - – Contrato 5230/2014",
        proprietario: "Caixa Econômica Federal - GILOG-GO",
        endereco: "Av. Anhanguera n. 5829 Centro - Goiânia",
        periodo: "2014",
        contato: "(GILOG) 62 3216-1973"
    }
]