import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="site-footer">
            <div className="container">

                <div class="footer-col">
                    <img src="./logo/logo-branco.png"
                    alt="logo" className="logo" />
                </div>

                <div class="footer-col">
                    <div className="dados-contato">
                        <div className="icon-container">
                            <i class="fas fa-phone-alt"></i>
                            <span> - (62) 3911-0679</span>
                            <div class="cel-number">
                                <span> - (62) 99980 4796</span>
                            </div>
                            <div class="cel-number">
                                <span> - (62) 99972 2871</span>
                            </div>
                        </div>
                        <div className="icon-container">
                            <i class="fas fa-map-marker-alt"></i>
                            <span> - Av. Senador José Rodrigues de Moraes Neto, N° 2395,
                                     QD.38, LT.09, Parque Amazônia - Goiânia, GO, CEP 74840-080</span>
                        </div>
                        <div className="icon-container">
                            <i class="fas fa-at"></i>
                            <span> - contato@metropolisconsultoria.com.br</span>
                        </div>
                    </div>
                </div>

                <div class="footer-col last-col">
                    <div className="socials-container">
                        <a href="https://www.linkedin.com/in/metr%C3%B3polis-consultoria-e-planejamento-1117461b5/" 
                            className="social-icon" target="_blank" >
                            <i class="fab fa-linkedin-in fa-2x"></i>
                        </a>
                        <a href="https://web.facebook.com/Metr%C3%B3polis-Consultoria-e-Planejamento-110051400825423" 
                            className="social-icon" target="_blank" >
                            <i class="fab fa-facebook-f fa-2x"></i>
                        </a>
                        <a href="https://www.instagram.com/metropolisconsultoria/" 
                            className="social-icon" target="_blank" >
                            <i class="fab fa-instagram fa-2x"></i>
                        </a>
                    </div>
                </div>

            </div>
        </footer>
    );
}

export default Footer;