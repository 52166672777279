import React from 'react';
import './QuemSomos.css';
import ImageHover from './ImageHover'


class QuemSomos extends React.Component {

    constructor() {
        super()
        this.state = {
            hoverSede: false,
            hoverCaapi: false
        }
    }
    
    render() {
        return (
            <div class="quemSomos">
                <div class="banner">
                    <div class="banner-container">
                        <h1>A EMPRESA</h1>
                    </div>
                </div>

                <div class="intro-container">
                    <div class="intro">
                        <p>
                            A MetróPolis Consultoria e Planejamento Ltda. - EPP é uma 
                            empresa constituída em 1995, especializada na integração das 
                            áreas de arquitetura, engenharia e urbanismo.
                        </p>
                        <p>
                        Trabalhamos em consultorias técnicas, coordenação e elaboração de 
                        projetos de edificações e infraestrutura urbana, orçamento de obras, 
                        execução de construções novas e reformas de edificações, fiscalização 
                        de obras, planejamento urbano, organização de setores de infraestrutura 
                        de órgãos públicos e cursos/treinamentos de capacitação nas áreas de arquitetura e engenharia.
                        </p>
                        <p>
                        Nossa missão e compromisso são gerar a satisfação, o bem-estar e a inclusão 
                        dos usuários das edificações e espaços urbanos, através das boas práticas e da 
                        inovação, prezando por soluções apontadas para um desenvolvimento sustentável das cidades.
                        </p>
                    </div>
                    <div class="sede" >
                        <img src="./fotos/fachada.png" alt="sede" class="constr-img" />
                        <ImageHover mouseOn={this.state.hoverSede} description={"Sede da Metrópolis"} />
                    </div>
                </div>

                <div class="intro-container">
                    <div class="caapi">
                        <img src="./fotos/residencial-caapi.png" alt="caapi" class="constr-img" />
                    </div>
                    <div class="objetivos-container">
                        <h1 class="objetivos">Princípios de Atuação/Valores</h1>

                        <div class="listagem">
                            <div class="objetivo-container">
                                <div class="icon-obj">
                                    <i class="fas fa-plus"></i>
                                </div>
                                <div class="objetivo">
                                    <p>Atuar com ética e responsabilidade socioambiental na condução dos 
                                        trabalhos;</p>
                                </div>
                            </div>

                            <div class="objetivo-container">
                                <div class="icon-obj">
                                    <i class="fas fa-plus"></i>
                                </div>
                                <div class="objetivo">
                                    <p>Investir no conhecimento e no aprimoramento contínuo das equipes de 
                                        trabalho com foco nos clientes;</p>
                                </div>
                            </div>

                            <div class="objetivo-container">
                                <div class="icon-obj">
                                    <i class="fas fa-plus"></i>
                                </div>
                                <div class="objetivo">
                                    <p>Buscar a inovação e a sustentabilidade nos resultados de produtos 
                                        e serviços prestados;</p>
                                </div>
                            </div>

                            <div class="objetivo-container">
                                <div class="icon-obj">
                                    <i class="fas fa-plus"></i>
                                </div>
                                <div class="objetivo">
                                    <p>Trabalhar em equipes multidisciplinares para integrar o conhecimento 
                                        nas diferentes etapas do processo de produção;</p>
                                </div>
                            </div>

                            <div class="objetivo-container">
                                <div class="icon-obj">
                                    <i class="fas fa-plus"></i>
                                </div>
                                <div class="objetivo">
                                    <p>Entregar serviços que correspondam às expectativas dos clientes em 
                                        qualidade, prazo, eficácia e custos.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuemSomos;