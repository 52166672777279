import React from 'react';
import './Home.css';
import {Atuacao_Content} from './Atuacao';

function Home() {
  return (
    <div className="App">
      <Atuacao_Content />
    </div>
  );
}

export default Home;
