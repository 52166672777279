export const socios = [
    {
        "prof": "Arq.",
        "nome": "Marco Antonio de Oliveira",
        "documento": "CAU-BR",
        "id": "A9574-5"
    },

    {
        "prof": "Eng. Civil",
        "nome": "Paulo Sérgio Nunes Menezes",
        "documento": "CREA",
        "id": "4146/D-GO"
    }
];

export const arquitetos = [
    {
        "prof": "Arq.",
        "nome": "Bruno Cabral Nascimento",
        "documento": "",
        "id": ""
    },

    {
        "prof": "Arq.",
        "nome": "Débora Christina Mesquita Cardozo",
        "documento": "",
        "id": ""
    },

    {
        "prof": "Arq.",
        "nome": "Doris de Melo Diogo Cruz",
        "documento": "",
        "id": ""
    },

    {
        "prof": "Arq.",
        "nome": "Fred Diogo Cruz",
        "documento": "",
        "id": ""
    },

    {
        "prof": "Arq.",
        "nome": "Patrícia de Faria",
        "documento": "",
        "id": ""
    }
];

export const engenheiros_civis = [
    {
        "prof": "Eng. Civil",
        "nome": "Dea Cleres Moreira Montenegro",
        "documento": "CREA",
        "id": "Eng. Eletricista"
    },

    {
        "prof": "Eng. Civil",
        "nome": "Emmanuel Gomes Fontenelle",
        "documento": "CREA",
        "id": "5167/D-GO"
    },

    {
        "prof": "Eng. Civil",
        "nome": "Honório Cesar Alfonso Castro",
        "documento": "CREA",
        "id": "7396/D-GO"
    },

    {
        "prof": "Eng. Civil",
        "nome": "Luiza Oliveira Maroclo",
        "documento": "CREA",
        "id": "5811/D-GO"
    }
];

export const engenheiros_eletr = [
    {
        "prof": "Eng. Eletricista",
        "nome": "Patrícia Nasser Naves",
        "documento": "CREA",
        "id": "2180/D-GO"
    },

    {
        "prof": "Eng. Eletricista",
        "nome": "Eduardo Nunes Ribeiro",
        "documento": "CREA",
        "id": "15236/D-GO"
    },

    {
        "prof": "Eng. Eletricista e Civil",
        "nome": "Leandro Melo Andrade e Silva",
        "documento": "CREA",
        "id": "21988/D-GO"
    },

    {
        "prof": "Eng. Eletricista",
        "nome": "Claudenis de Souza Silva",
        "documento": "CREA",
        "id": "60994/D-MS"
    }
];

export const engenheiros_mec = [
    {
        "prof": "Eng. Mecânico",
        "nome": "Jossemar Oliveira Souza",
        "documento": "CREA",
        "id": "18308/D-MS"
    }
];