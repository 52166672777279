import React from 'react';
import './ImageHover.css'

function ImageHover(props) {
    console.log("yo");
    if(props.mouseOn === true) {
        return (
            <div class="image-desc-container">
                <p class="desc">{props.description}</p>
            </div>
        );
    }
    else {
        return(
            <p></p>
        );
    }
}

export default ImageHover;