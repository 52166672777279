import React from 'react';
import './Equipe.css';
import {socios, arquitetos, engenheiros_civis, engenheiros_eletr, engenheiros_mec } from './equipe_membros';

function Equipe() {
    return (
        <div>
            <div class="projetos-banner">
               <h1>EQUIPE</h1>
            </div>

            <div class="equipe">

                <div class="equipe-cargo">
                    <div>
                        <h1 class="cargo-titulo">SÓCIOS DIRETORES</h1>
                    </div>
                    <div className="equipe-membro">
                        {socios.map((data, key) => {
                            return (
                                <div key={key} className="equipe-membro-info">
                                    <p>{data.prof} {data.nome} - {data.documento} {data.id}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div class="equipe-cargo">
                    <div>
                        <h1 class="cargo-titulo">ARQUITETOS</h1>
                    </div>
                    <div className="equipe-membro">
                        {arquitetos.map((data, key) => {
                            return (
                                <div key={key} className="equipe-membro-info">
                                    <p>{data.prof} {data.nome}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div class="equipe-cargo">
                    <div>
                        <h1 class="cargo-titulo">ENGENHEIROS CIVIS</h1>
                    </div>
                    <div className="equipe-membro">
                        {engenheiros_civis.map((data, key) => {
                            return (
                                <div key={key} className="equipe-membro-info">
                                    <p>{data.prof} {data.nome} - {data.documento} {data.id}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div class="equipe-cargo">
                    <div>
                        <h1 class="cargo-titulo">ENGENHEIROS ELETRICISTAS</h1>
                    </div>
                    <div className="equipe-membro">
                        {engenheiros_eletr.map((data, key) => {
                            return (
                                <div key={key} className="equipe-membro-info">
                                    <p>{data.prof} {data.nome} - {data.documento} {data.id}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div class="equipe-cargo">
                    <div>
                        <h1 class="cargo-titulo">ENGENHEIROS MECÂNICOS</h1>
                    </div>
                    <div className="equipe-membro">
                        {engenheiros_mec.map((data, key) => {
                            return (
                                <div key={key} className="equipe-membro-info">
                                    <p>{data.prof} {data.nome} - {data.documento} {data.id}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Equipe;