import React from 'react';
import {Navbar, Nav} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Header.css';
import { NavLink } from 'react-router-dom';

function BarraNav() {
    return(
        <div className="nav-container">
            <Navbar className="Navbar" expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                    <NavLink to="/quem-somos" className="nav-element" >QUEM SOMOS</NavLink>
                    <NavLink to="/atuacao" className="nav-element" >ATUAÇÃO</NavLink>
                    <NavLink to="/portfolio" className="nav-element" >PORTFÓLIO</NavLink>
                    <NavLink to="/equipe" className="nav-element" >EQUIPE</NavLink>
                    <NavLink to="/contato" className="nav-element" >CONTATO</NavLink>
                    <NavLink to="/links" className="nav-element" >LINKS</NavLink>
                    <NavLink to="/publicacoes" className="nav-element" >PUBLICAÇÕES</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

function Logo() {
    return (
        <div className="logo-container">
            <NavLink to="/">
                <img src="./logo/logo-branco.png"
                 alt="logo" className="image-size" />
            </NavLink>
        </div>
    );
}

function Header() {
    return(
        <div className="site-header">
            <Logo />
            <BarraNav />
        </div>
    )
}

export default Header;