import React from 'react';
import './Projetos.css';
import { construcoes, reformas, consultoria } from './construcoes';

function ConstrucoesLista() {
    return (
        <div className="construcoes-container">
            <div className="construcao-container">
                {construcoes.map((data, key) => {
                    return (
                        <div key={key} className="construcao">
                            <p>Obra: {data.obra}</p>
                            <p>Prop.: {data.proprietario}</p>
                            <p>End.: {data.endereco} </p>
                            <p>Periodo: {data.periodo}</p>
                            <p>Contato: {data.contato} </p>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

function ConstrucoesImagens() {
    return (
        <div class="imagens-construcao-container">

            <div class="imagens-construcao">
                <div class="imagem-construcao-container">
                    <img src="./fotos/residencia-eduardo-1.png" alt="residencia-eduardo-1" class="imagem-construcao" />
                    <img src="./fotos/residencia-eduardo-2.png" alt="residencia-eduardo-2" class="imagem-construcao" />
                </div>
                <div class="imagem-construcao-container">
                    <h1 class="title-banner" >Construções</h1>
                    <img src="./fotos/nefroclinica.png" alt="nefroclinica" class="imagem-construcao" />
                </div>
            </div>

        </div>
    )
}

function ReformasLista() {
    return (
        <div className="construcoes-container">
            <div className="construcao-container">
                {reformas.map((data, key) => {
                    return (
                        <div key={key} className="construcao reforma">
                            <p>Obra: {data.obra}</p>
                            <p>Prop.: {data.proprietario}</p>
                            <p>End.: {data.endereco} </p>
                            <p>Periodo: {data.periodo}</p>
                            <p>Contato: {data.contato} </p>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

function ReformasImagens() {
    return (
        <div class="imagens-construcao-container">

            <div class="imagens-construcao">
                <div class="imagem-construcao-container">
                    <h1 class="projetos-banner">Reformas</h1>
                    <img src="./fotos/residencia-maria-amelia.png" alt="residencia-maria-amelia" class="imagem-constucao" />
                </div>
                <div class="imagem-construcao-container">
                    <img src="./fotos/residencia-eduardo-2-reforma.png" alt="residencia-eduardo-2-reforma" class="imagem-construcao" />
                </div>
            </div>

        </div>
    )
}

function ConsultoriaLista() {
    return (
        <div className="construcoes-container">
            <div className="construcao-container">
                {consultoria.map((data, key) => {
                    return (
                        <div key={key} className="consultoria">
                            <p>Serviço: {data.obra}</p>
                            <p>Prop.: {data.proprietario}</p>
                            <p>End.: {data.endereco} </p>
                            <p>Periodo: {data.periodo}</p>
                            <p>Contato: {data.contato} </p>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

function ConsultoriaImagens() {
    return (
        <div class="imagens-construcao-container">

            <div class="imagens-construcao">
                <div class="imagem-construcao-container">
                    <img src="./fotos/consultoria-procuradoria.png" alt="consultoria-procuradoria" class="imagem-constucao" />
                </div>
                <div class="imagem-construcao-container">
                    <h1 class="title-banner" >Projetos, Consultoria, Fiscalização de Obras</h1>
                    <img src="./fotos/consultoria-caixa.png" alt="consultoria-caixa" class="imagem-construcao" />
                </div>
            </div>

        </div>
    )
}


function Projetos() {
    return (
        <div class="projetos">
            <div class="projetos-banner">
               <h1>NOSSOS PROJETOS</h1>
            </div>

            <ConstrucoesImagens />

            <ConstrucoesLista />

            <ReformasImagens />

            <ReformasLista />

            <ConsultoriaImagens />

            <ConsultoriaLista />

        </div>
    )
}

export default Projetos;